export const getUsercentricsId = (type: string) => {
  switch (type) {
    case 'de-DE':
      return '9HszDxUc_'
    case 'en-GB':
      return 'yHxDbdEcXizfj9'
    case 'en-EU':
      return 'yF4fbUUmW'
    case 'it-IT':
      return '_tJ4KXqpG'
    case 'pl-PL':
      return 'niuIskuAB'
    case 'de-AT':
      return 'sJ-psuHZt'
    case 'es-ES':
      return 'IdSTGvcCF'
    case 'fr-FR':
      return 'Ft6vCSpvg'
    case 'de-CH':
      return 'y7dUOZnh5'
    case 'de':
      return 'y7dUOZnh5'
    case 'fr':
      return 'y7dUOZnh5'
    case 'it':
      return 'y7dUOZnh5'
    case 'nl-NL':
      return 'qZbVSdg3R'
    case 'sv-SE':
      return 'efxAOZzjs'
    case 'da-DK':
      return 'VZn3SRscx'
    case 'cs-CZ':
      return '_baLrWBYe03ZDw'
    case 'pt-PT':
      return 'tNWyIqHkFTY012'
    case 'fi-FI':
      return 'Rk7NJwcwMDffCo'
    case 'nn-NO':
      return 'HO7WdVVpCAFvMp'
    default:
      return ''
  }
}
