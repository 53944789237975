import { createContext, Dispatch } from 'react'

export type DesktopNaviState = {
  secondLevelToggle: boolean
  thirdLevelToggle: boolean
  secondLevelIndex: number
  thirdLevelIndex: number
  scrolledPastMetabar: boolean
}

export type DesktopNaviAction =
  | { type: 'set-secondLevelToggle'; payload: boolean }
  | { type: 'set-thirdLevelToggle'; payload: boolean }
  | { type: 'set-secondLevelIndex'; payload: number }
  | { type: 'set-thirdLevelIndex'; payload: number }
  | { type: 'set-scrolledPastMetabar'; payload: boolean }

export type ContextProps = {
  desktopNaviState: DesktopNaviState
  desktopNaviDispatch: Dispatch<DesktopNaviAction>
  setMobileFlyoutToggle: Dispatch<boolean>
  mobileFlyoutToggle: boolean
  setSearchValue: Dispatch<string>
  searchValue: string
  setSearchToggle: Dispatch<boolean>
  searchToggle: boolean
}

export const UIContext = createContext<Partial<ContextProps>>({})
