const path = require('path')

module.exports = {
  i18n: {
    defaultLocale: 'de-DE',
    locales: [
      'de-CH',
      'de-DE',
      'de-AT',
      'da-DK',
      'nl-NL',
      'en-EU',
      'en-GB',
      'fr-FR',
      'fr',
      'de',
      'it-IT',
      'it',
      'pl-PL',
      'es-ES',
      'sv-SE',
      'cs-CZ',
      'pt-PT',
      'fi-FI',
      'nn-NO',
    ],
    domains: [
      {
        // Note: subdomains must be included in the domain value to be matched
        // e.g. www.example.com should be used if that is the expected hostname
        domain: 'www.benuta.at',
        defaultLocale: 'de-AT',
      },
      {
        domain: 'www.benuta.ch',
        defaultLocale: 'de-CH',
        locales: ['de-CH', 'de', 'fr', 'it'],
      },
      {
        domain: 'www.benuta.dk',
        defaultLocale: 'da-DK',
      },
      {
        domain: 'www.benuta.nl',
        defaultLocale: 'nl-NL',
      },
      {
        domain: 'www.benuta.co.uk',
        defaultLocale: 'en-GB',
      },
      {
        domain: 'www.benuta.fr',
        defaultLocale: 'fr-FR',
      },
      {
        domain: 'www.benuta.it',
        defaultLocale: 'it-IT',
      },
      {
        domain: 'www.benuta.pl',
        defaultLocale: 'pl-PL',
      },
      {
        domain: 'www.benuta.es',
        defaultLocale: 'es-ES',
      },
      {
        domain: 'www.benuta.se',
        defaultLocale: 'sv-SE',
      },
      {
        domain: 'www.benuta.de',
        defaultLocale: 'de-DE',
      },
      {
        domain: 'www.benuta.eu',
        defaultLocale: 'en-EU',
      },
      {
        domain: 'www.benuta.cz',
        defaultLocale: 'cs-CZ',
      },
      {
        domain: 'www.benuta.pt',
        defaultLocale: 'pt-PT',
      },
      {
        domain: 'www.benuta.fi',
        defaultLocale: 'fi-FI',
      },
      {
        domain: 'www.benuta.no',
        defaultLocale: 'nn-NO',
      },
    ],
    localeDetection: false,
  },
  localePath: path.resolve('./public/locales'),
}
