/*artsy/fresnel is a library for SSR conditional rendering depending on screen sizes
  and should replace useMedia if element should or should not be visible at a certain 
  screen width.
  Example: 
    <Media lessThan="lgx">
        <MobileHeader />
      </Media>
      <Media greaterThanOrEqual="lgx">
        <DesktopHeader />
      </Media>

    Beware: Media will create a div around the children inside. 
    So if you place elements relatively or if you want to give the children h-full and other styles depending on the parent, you have to consider this.
    Also: Overlay/Modal libraries will not work well with the Media container.
*/

import { createMedia } from '@artsy/fresnel'

const ExampleAppMedia = createMedia({
  breakpoints: {
    xs: '450',
    sm: '600',
    md: '768',
    lg: '1024',
    lgx: '1200',
    xl: '1440',
    '2xl': '1920',
    '2k': '2560',
    '4k': '3840',
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = ExampleAppMedia
