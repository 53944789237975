import { ReactNode, useReducer, useState } from 'react'
import { UIContext, DesktopNaviState, DesktopNaviAction } from './UIStore'

type Props = {
  children: ReactNode
}

export const UIProvider = ({ children }: Props) => {
  const initialState: DesktopNaviState = {
    secondLevelToggle: false,
    thirdLevelToggle: false,
    secondLevelIndex: 0,
    thirdLevelIndex: 0,
    scrolledPastMetabar: false,
  }

  const navigationReducer = (
    state: DesktopNaviState,
    action: DesktopNaviAction,
  ): DesktopNaviState => {
    switch (action.type) {
      case 'set-secondLevelToggle': {
        return { ...state, secondLevelToggle: action.payload }
      }
      case 'set-thirdLevelToggle': {
        return { ...state, thirdLevelToggle: action.payload }
      }
      case 'set-secondLevelIndex': {
        return { ...state, secondLevelIndex: action.payload }
      }
      case 'set-thirdLevelIndex': {
        return { ...state, thirdLevelIndex: action.payload }
      }
      case 'set-scrolledPastMetabar': {
        return { ...state, scrolledPastMetabar: action.payload }
      }
      default:
        return initialState
    }
  }
  const [desktopNaviState, desktopNaviDispatch] = useReducer(navigationReducer, initialState)
  const [mobileFlyoutToggle, setMobileFlyoutToggle] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchToggle, setSearchToggle] = useState<boolean>(false)

  return (
    <UIContext.Provider
      value={{
        desktopNaviState,
        desktopNaviDispatch,
        mobileFlyoutToggle,
        setMobileFlyoutToggle,
        searchValue,
        setSearchValue,
        setSearchToggle,
        searchToggle,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}
